export const configs = {};

export const gateNames = {
  splitPay: 'split_pay',
  xrTransparency: 'pro_xr_transparency',
  selfServiceFg: 'feature_self_service',
};

export const experiments = {
  preScreeningXP: {
    name: 'xp_prescreening',
    parameters: {
      applicationVersion: 'application_version',
    },
  },
};
