import { Box, Button, TextField, VStack, HStack } from '@medely/ui-kit';
import { FixedFooterLayout } from '@src/components/FixedFooterLayout/FixedFooterLayout';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useProNavigate } from '@src/contexts';
import usePaymentInfo from '@src/hooks/usePaymentInfo';
import L from 'lodash';
import {
  BankAccountFormT,
  BankAccountFormSchema,
} from '@src/models/settings/financial/BankAccountForm.schema';

type Props = {
  onError: () => void;
  onSuccess: () => void;
};

function ButtonActions({ onError, onSuccess }: Props) {
  const { navigate } = useProNavigate();
  const { handleSubmit, reset } = useFormContext();
  const { addDirectDeposit, isAddDirectDepositLoading } = usePaymentInfo();

  function onSubmit(data: BankAccountFormT) {
    addDirectDeposit(L.omit(data, 'confirm_account_number'), {
      onSuccess: () => {
        reset();
        onSuccess();
        navigate('PayoutTaxes');
      },
      onError,
    });
  }

  function onCancel() {
    navigate('PayoutTaxes');
  }

  return (
    <HStack gap={1}>
      <Box width={'50%'}>
        <Button variant="outlined" fullWidth onClick={onCancel}>
          Cancel
        </Button>
      </Box>
      <Box width={'50%'}>
        <Button
          fullWidth
          onClick={() => handleSubmit((values) => onSubmit(values as BankAccountFormT))()}
          loading={isAddDirectDepositLoading}
        >
          Save
        </Button>
      </Box>
    </HStack>
  );
}

export const BankAccountForm = ({ onError, onSuccess }: Props) => {
  const methods = useForm<BankAccountFormT>({ resolver: yupResolver(BankAccountFormSchema) });

  return (
    <FormProvider {...methods}>
      <FixedFooterLayout footerContent={<ButtonActions onError={onError} onSuccess={onSuccess} />}>
        <VStack gap={2}>
          <TextField name="routing_number" label="Routing number" />
          <TextField name="account_number" label="Account number" />
          <TextField name="confirm_account_number" label="Confirm account number" />
        </VStack>
      </FixedFooterLayout>
    </FormProvider>
  );
};
