import { Dialog } from '@medely/web-components';
import { useCurrentUser } from '@src/hooks';
import React from 'react';
import { ExperienceSummaryResumeModalProps } from './types';
import { Box, Button, FileField, Form, HStack, SubmitButton, yup } from '@medely/ui-kit';
import useResumeSubmitMutation from './useResumeMutations';

export const parseFileString = (fileString: string) => {
  const lastSlashIdx = fileString.lastIndexOf('/');
  const fileNameAfterSlash = fileString.slice(lastSlashIdx + 1);
  const questionMarkIdx = fileNameAfterSlash.indexOf('?') || undefined;
  return fileNameAfterSlash.slice(0, questionMarkIdx);
};

export const ExperienceSummaryResumeModal = ({
  open,
  onClose,
}: ExperienceSummaryResumeModalProps) => {
  const { currentUser } = useCurrentUser();
  const { submitResume, isLoading } = useResumeSubmitMutation(onClose);

  const resume = currentUser?.professional?.resume;

  const handleContinue = async (values: { resume: File | undefined }) => {
    await submitResume(values);
  };

  return (
    <Dialog
      open={open}
      disableRestoreFocus
      title={!!resume ? `Replace resume` : 'Upload resume'}
      content={
        <>
          <Form
            defaultValues={{ resume }}
            handleSubmit={handleContinue}
            schema={yup.object({
              resume: yup
                .mixed()
                .file(
                  ['.doc', '.docx', '.docs', '.pdf', '.txt', '.jpeg', '.jpg', '.png'],
                  false,
                  !!resume,
                ),
            })}
          >
            <Box my={2}>
              <FileField
                acceptedFileTypes={[
                  '.doc',
                  '.docx',
                  '.docs',
                  '.pdf',
                  '.txt',
                  '.jpeg',
                  '.jpg',
                  '.png',
                ]}
                name="resume"
                label="Upload your resume"
                parseValue={(value) => {
                  if (typeof value === 'string') {
                    return parseFileString(value);
                  }
                  return value;
                }}
              />
            </Box>
            <HStack>
              <Box mr={0.5} width="100%">
                <Button variant="outlined" onClick={onClose} fullWidth>
                  Back
                </Button>
              </Box>
              <Box ml={0.5} width="100%">
                <SubmitButton fullWidth disabled={isLoading}>
                  Save
                </SubmitButton>
              </Box>
            </HStack>
          </Form>
        </>
      }
    />
  );
};
