import { Box, Heading } from '@medely/ui-kit';
import { useCurrentUser } from '@src/hooks';
import React from 'react';
import { BackgroundCheckNotCompleted } from './components/BackgroundCheckNotCompleted';
import L from 'lodash';
import { AccountCard } from './components/AccountCard';
import { OtherAccountsSection } from './components/OtherAccountsSection';
import { TaxDocumentsSection } from './components/TaxDocumentsSection';

export const PayoutsTaxes = () => {
  const { currentUser } = useCurrentUser();
  const hasBackgroundChecks = currentUser?.professional?.background_checks?.length;
  const stripeAccounts = L.sortBy(currentUser?.professional?.stripe_accounts ?? [], (a) =>
    a.active ? -1 : 0,
  );

  const activeAccount = stripeAccounts?.[0] || [];

  const otherAccounts = stripeAccounts.slice(1, stripeAccounts?.length + 1);

  return (
    <Box>
      <Heading size="m" data-testid="financial-info-title">
        Payouts and Taxes
      </Heading>
      {!hasBackgroundChecks ? (
        <BackgroundCheckNotCompleted />
      ) : (
        <Box>
          <AccountCard currentUserName={currentUser?.name || ''} account={activeAccount} />
          <OtherAccountsSection otherAccounts={otherAccounts} />
          <TaxDocumentsSection />
        </Box>
      )}
    </Box>
  );
};
