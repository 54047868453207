import { IPosition, ISpecialty } from '@medely/types';
import { Form } from '@medely/ui-kit';
import React from 'react';
import { createSpecialtiesPayload, getSpecialtiesPreSelectedIds } from '../utils';
import useSpecialtiesMutations from '@src/hooks/useSpecialtiesMutations';
import { SpecialtiesForm } from './components/SpecialtiesForm';
import { specialtiesFormSchema } from './specialtiesFormSchema';

type ValuesType = {
  specialty_ids: number[];
};

type SpecialtiesViewProps = {
  positionWithSpecialtiesInfo: IPosition | undefined;
  professionalSpecialties: ISpecialty[] | undefined;
  onBackClick: (params: undefined) => void;
};

export const SpecialtiesView = ({
  positionWithSpecialtiesInfo,
  professionalSpecialties,
  onBackClick,
}: SpecialtiesViewProps) => {
  const { submitSpecialtiesInfo, isSpecialtiesLoading } = useSpecialtiesMutations();

  const preSelectedSpecialties = getSpecialtiesPreSelectedIds({
    positionWithSpecialtiesInfo,
    professionalSpecialties,
  });

  const defaultValues = {
    specialty_ids: preSelectedSpecialties || [],
  };

  const options = positionWithSpecialtiesInfo?.specialties.map((specialty) => {
    return {
      value: specialty.id,
      label: specialty.label,
    };
  });

  const handleSubmit = async (values: ValuesType) => {
    const payloadInfo = createSpecialtiesPayload({
      preSelectedSpecialties,
      selectedSpecialties: values.specialty_ids,
    });

    if (payloadInfo.hasNoPayloadChange) {
      return onBackClick(undefined);
    }

    await submitSpecialtiesInfo(payloadInfo.payload, {
      onSuccess: () => {
        onBackClick(undefined);
      },
    });
  };

  return (
    <Form handleSubmit={handleSubmit} schema={specialtiesFormSchema} defaultValues={defaultValues}>
      <SpecialtiesForm
        positionWithSpecialtiesInfo={positionWithSpecialtiesInfo}
        onBackClick={onBackClick}
        isSpecialtiesLoading={isSpecialtiesLoading}
        options={options}
        preSelectedSpecialties={preSelectedSpecialties}
      />
    </Form>
  );
};
