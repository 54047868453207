import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';
import { AxiosResponse } from 'axios';

const useSpecialtiesMutations = (): {
  submitSpecialtiesInfo: UseMutateFunction<AxiosResponse<any>, any, any, unknown>;
  isSpecialtiesLoading: boolean;
} => {
  const { axios } = useNetworkRequest();
  const queryClient = useQueryClient();

  const { mutate: submitSpecialtiesInfo, isLoading } = useMutation(
    (values) => axios.post(`/v3/professional/specialties`, values),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['currentUser']);
        queryClient.invalidateQueries(['ProfilePositionsWithSpecialties']);
      },
    },
  );

  return { submitSpecialtiesInfo, isSpecialtiesLoading: isLoading };
};

export default useSpecialtiesMutations;
