import { IJob } from '@medely/types';
import { Box, Icon, Text } from '@medely/ui-kit';
import React from 'react';
import { JobDetailsListItem } from '../../JobDetailsListItem';
import { ShiftLocation } from '../../ShiftLocation';
import { ShiftDate } from '../../ShiftDate';
import { useCurrentUser } from '@src/hooks';
import { DisplayHelpers } from '@src/utils';
import { COVID_REQUIREMENTS } from '@src/constants';
import { HelpCenterLink } from '@src/components/helpCenterLink';

type AvailableGeneralInfoProps = {
  job: IJob;
  smallIcon?: boolean;
};

export const AvailableGeneralInfo = ({ job, smallIcon }: AvailableGeneralInfoProps) => {
  const { isSaas, isAgency, currentUser } = useCurrentUser();
  const covidRequirement =
    job.location?.covid_requirement || job.location?.state?.covid_requirement;
  const covidText =
    COVID_REQUIREMENTS.info[covidRequirement as keyof typeof COVID_REQUIREMENTS.info] ?? '';
  const bookedOrInProgressOrCompleted = ['booked', 'clocked_in', 'clocked_out'].includes(
    job.status,
  );

  // The PR with the new icons is in CR
  return (
    <>
      <Box py={1.5}>
        <JobDetailsListItem
          icon={
            <Icon
              name="calendar"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
            // location-dot
          }
          title="Location"
        >
          <ShiftLocation
            job={job}
            bookVariant={
              !bookedOrInProgressOrCompleted || isSaas || isAgency ? 'pre-book' : 'post-book'
            }
            distanceVariant="local"
            userCoordinates={currentUser?.coordinates}
          />
        </JobDetailsListItem>
      </Box>
      <Box py={1.5}>
        <JobDetailsListItem
          icon={
            <Icon
              name="calendar"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
            // light
          }
          title="Date"
        >
          <ShiftDate job={job} displayStartingSoonBadge />
        </JobDetailsListItem>
      </Box>
      <Box py={1.5}>
        <JobDetailsListItem
          icon={
            <Icon
              name="clock"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
            // light
          }
          title="Shift time"
        >
          <Text size="m">{DisplayHelpers.job.time(job)}</Text>
        </JobDetailsListItem>
      </Box>
      {job.specialties && (
        <Box py={1.5}>
          <JobDetailsListItem
            icon={
              <Icon
                name="calendar"
                iconSize={smallIcon ? '14px' : '20px'}
                color="text.primary"
                variant="custom"
              />
            }
            title="Specialties"
            // starOfLife
          >
            <Text size="m">{DisplayHelpers.job.specialties(job)}</Text>
          </JobDetailsListItem>
        </Box>
      )}
      {job.surgery_types && (
        <Box py={1.5}>
          <JobDetailsListItem
            icon={
              <Icon
                name="calendar"
                iconSize={smallIcon ? '14px' : '20px'}
                color="text.primary"
                variant="custom"
              />
            }
            title="Procedures"
            // SCALPEL
          >
            <Text size="m">{DisplayHelpers.job.procedures(job)}</Text>
          </JobDetailsListItem>
        </Box>
      )}
      <Box py={1.5}>
        <JobDetailsListItem
          icon={
            <Icon
              name="calendar"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
          }
          // HEADSIDEMEDICAL
          title="Patient population"
        >
          <Text size="m">{DisplayHelpers.job.patientPopulation(job)}</Text>
        </JobDetailsListItem>
      </Box>
      {covidRequirement && (
        <Box mb={3} testId="job-highlights-covid-requirement">
          <JobDetailsListItem
            icon={
              <Icon
                name="calendar"
                iconSize={smallIcon ? '14px' : '20px'}
                color="text.primary"
                variant="custom"
              />
            }
            // SYRINGE
            title="Covid protocol"
          >
            <>
              <Text size="m">{covidText}</Text>
              <Box testId="job-highlights-covid-requirement-link">
                <HelpCenterLink text="Learn more" configKey="covidVaccineOrTest" />
              </Box>
            </>
          </JobDetailsListItem>
        </Box>
      )}
    </>
  );
};
