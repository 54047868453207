import * as L from 'lodash';
import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import type { TaxInfo } from '@src/models/settings/tax-info/TaxInfo.schema';
import { useNetworkRequest } from './useNetworkRequest';
import type { AxiosResponse } from 'axios';

interface TaxInfoEditParams extends TaxInfo {
  id: string | number;
}

const useTaxInfo = (): {
  addTaxInfo: UseMutateAsyncFunction<AxiosResponse<unknown>, unknown, TaxInfo, unknown>;
  editTaxInfo: UseMutateAsyncFunction<AxiosResponse<unknown>, unknown, TaxInfoEditParams, unknown>;
} => {
  const { axios } = useNetworkRequest();

  const { mutateAsync: addTaxInfo } = useMutation((values: TaxInfo) => {
    return axios.post('/v3/professional/stripe_accounts', {
      stripe_account: values,
    });
  });

  const { mutateAsync: editTaxInfo } = useMutation((values: TaxInfoEditParams) => {
    const isIndividual = Number(values.tax_classification_id) === 1;
    const individualParams = ['first_name', 'last_name'];
    const companyParams = ['company_name', 'representative'];
    return axios.put(`/v3/professional/stripe_accounts/${values.id}`, {
      stripe_account: L.omit(values, [
        'external_accounts',
        'state',
        'id',
        ...(isIndividual ? companyParams : individualParams),
      ]),
    });
  });

  return {
    addTaxInfo,
    editTaxInfo,
  };
};

export default useTaxInfo;
